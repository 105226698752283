import CourseView from "../features/CourseView/CourseView";
import Dashboard from "../features/Dashboard/Dashboard";
import Player from "../features/Player/Player";
import SearchResults from "../features/Search/SearchResults/SearchResults";
import Help from "../features/Help/HelpPage/Help";
import Profile from "../features/Profile/Profile";
import UserManagement from "../features/UserManagement/UserManagement";
import Timeline from "../features/Reports/Timeline/Timeline";
import DeviceSelection from "../features/DeviceSelection/DeviceSelection";

const AppRoutes = [
	{
		path: "/dashboard",
		element: <Dashboard />
	},
	{
		path: "/course-view",
		element: <CourseView />
	},
	{
		path: "/player",
		element: <Player />
	},
	{
		path: "/searchresults",
		element: <SearchResults />
	},
	{
		path: "/help",
		element: <Help />
	},
	{
		path: "/profile",
		element: <Profile />
	},
	{
		path: "/timeline",
		element: <Timeline />
	},
	{
		path: "/usermanagement",
		element: <UserManagement />
	},
	{
		path: "/deviceselection",
		element: <DeviceSelection />
	}
];

export default AppRoutes;
