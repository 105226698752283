import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, ButtonType } from "../../../components/Button/Button";
import "./Courses.scss";

const Courses = ({ onCourseCountChange, contentItems }) => {

	useEffect(() => {
		if (contentItems) {
			onCourseCountChange(contentItems.length);
		}
	}, [contentItems, onCourseCountChange]);

	return (
		<>
			{contentItems && contentItems.map(course => {
				const totalProgress = course.SubContent.reduce((acc, module) => acc + module.Progress, 0);
				const courseStarted = totalProgress > 0;

				return (
					<div className="course" key={course.Id}>
						<h2>{course.Name}</h2>
						<div className="modules">
							<div className="course-image" style={{ backgroundImage: `url(${course.ImageUri})` }} />
							{course.SubContent.slice(0, 4).map(module => (
								<Link to={`/course-view#courseId-${course.Id}-moduleId-${module.Id}`} className="module" key={module.Id}>
									<p>{module.Name}</p>
									<div className="percent">
										<span className="percent-bar" style={{ width: `${module.Progress}%` }}></span>
										<h4>{module.Progress}%</h4>
									</div>
								</Link>
							))}
						</div>
						<div className="buttons">
							<div className="buttons-left">
								<Button to={`/course-view#courseId-${course.Id}`} type={ButtonType.Tertiary} text="View Full Course" scroll={true} />
							</div>
							<div className="buttons-right">
								<Button text={courseStarted ? "Continue" : "Start"} to={`/player#courseId-${course.Id}-moduleId-${course.LastViewed.ContentId}-subModuleId-${course.LastViewed.SubContentId}`} />
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default Courses;
