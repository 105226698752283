import { DataFetcher } from "components/DataFetcher";
import DataTable from "components/DataTable/DataTable";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import { FilterMatchMode } from "primereact/api";
import { useCallback, useEffect, useState } from "react";
import DateRangeInput from "../components/DateRangeInput";
import "./Timeline.scss";
import { getActivityClass, getActivityDescription, getActivityTitle } from "./activityUtilities";

const Timeline = () => {
	const { gradient, watermark } = useBranding();
	const [fetchData, setFetchData] = useState({});
	const [fetchStep, setFetchStep] = useState(0);
	const [data, setData] = useState(null);
	const [range, setRange] = useState({});
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS }
	});
	const [earliestDataDate, setEarliestDataDate] = useState("");
	const [timelineData, setTimelineData] = useState(null);

	useEffect(() => {
		setFetchStep(1);
	}, []);

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	/**
	 * Effect for setting the URL for the DataFetcher.
	 * A warning for missing "range" object occurs; objects in useEffect dependencies cause infinite loops.
	 */
	useEffect(() => {
		if (fetchStep === 1) {
			setFetchRequestData("/api/Report/Timeline/FirstActivity/ByCompany");
		}
		else if (range && "from" in range && "to" in range) {
			const uriParam = range.from.getTime() + "," + range.to.getTime();
			setFetchRequestData("api/Report/Timeline/ByCompany/" + uriParam);
		}
	}, [fetchStep, range.from, range.to, setFetchRequestData]);
	
	const handleFilter = useCallback((searchValue) => {
		const value = searchValue;
		let _filters = { ...filters };
		_filters["global"].value = value;
		setFilters(_filters);
	}, [filters]);
	
	const processTimelineContent = useCallback((content, error) => {
		for (let item of content) {
			item.filter = getActivityTitle(item.activity) + getActivityDescription(item.activity, item.user, item.obj);
			item.filter = item.filter.replace(/<[^>]*>?/gm, "");
		}
		setTimelineData(content);
		handleFilter(""); // Adding this as a dependency causes several "Maximum update depth exceeded" warnings in DevTools (despite it being a callback).
	}, []);

	// Effect for processing the returned data from the DataFetcher.
	useEffect(() => {
		if (data) {
			if (typeof data === "string") {
				setEarliestDataDate(data);
				setFetchStep(2);
			}
			else if (Array.isArray(data)) {
				processTimelineContent(data, null);
				setFetchStep(0);
			}
		}
	}, [data, processTimelineContent]);

	const prettyPrintDate = (item) => {
		let date = new Date(item);
		let datePart = date.toLocaleDateString("en-GB", { "day": "numeric", "month": "numeric", "year": "2-digit" });
		let timePart = date.toLocaleTimeString("en-GB", { "hour": "numeric", "minute": "numeric" });
		return <><strong>{datePart}</strong> {timePart}</>;
	};

	const prettyPrintActivity = (item) => {
		const className = getActivityClass(item);
		const title = getActivityTitle(item);
		return <div className={`activity-pill ${className}`}>{title}</div>;
	};

	const prettyPrintDetails = (item) => {
		return getActivityDescription(item.activity, item.user, item.obj);
	};

	const columns = [
		{
			field: "Date",
			headerName: "Date",
			class: "date-column",
			valueGetter: (data) => { return prettyPrintDate(data.timestamp); }
		},
		{
			field: "Activity",
			headerName: "Activity",
			class: "activity-column",
			valueGetter: (data) => { return prettyPrintActivity(data.activity); }
		},
		{
			field: "Details",
			headerName: "Details",
			valueGetter: (data) => { return prettyPrintDetails(data); }
		}
	];

	return (
		<div className="gutters">
			<DataFetcher fetchData={fetchData} setData={setData} />
			<Pod title="Timeline" gradient={gradient} watermark={watermark}>
				<div className="timeline-container">
					<DateRangeInput earliestDataDate={earliestDataDate} setRange={setRange} />
					<DataTable columns={columns} rows={timelineData} filters={filters} globalFilterFields={["filter"]} handleFilter={handleFilter} columnIdName="Id" />
				</div>
			</Pod>
		</div>
	);
};

export default Timeline;