import { Outlet } from "react-router-dom";
import Header from "../features/Header/Header";
import BackToTop from "components/BackToTop/BackToTop";
import Modal from "components/Modal/Modal";

const Layout = () => {
	return (
		<>
			<Header />
			<Outlet />
			<BackToTop />
			<Modal /> 
		</>
	);
};

export default Layout;
