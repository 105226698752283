import './ContentGroup.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ContentGroup = ({ id, name, onRemove, disabled, hovered, onMouseEnter, onMouseLeave }) => {

	return (
		<span
			className={`content-group group-${id} ${disabled ? 'disabled' : ''} ${hovered ? 'hovered' : ''}`}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<span className="cross" onClick={() => onRemove(id)}>
				<FontAwesomeIcon icon={faXmark} />
			</span>
			<span className="content-group-text">{name}</span>
		</span>
	);
};

export default ContentGroup;
