import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './DisplaySeats.scss';

const DisplaySeats = ({ maxSeats, usedSeats }) => {
	const barWidthPercent = (usedSeats / maxSeats) * 100;

	return (
		<div className="displaySeats">
			<h3>
				You have {maxSeats - usedSeats} seat(s) remaining
				<span className="popup-container">
					<FontAwesomeIcon icon={faCircleInfo} className="info-icon" />
					<div className="popup">
						<p>To add more seats to your package, please contact us at ITEL on the number</p>
						<p><strong>01244 670200</strong></p>
					</div>
				</span>
			</h3>
			<div className="displaySeats-amount">
				<span
					className="displaySeats-amount-bar"
					style={{ width: `${barWidthPercent}%` }}
				></span>
				<p>{usedSeats} / {maxSeats}</p>
			</div>
		</div>
	);
};

export default DisplaySeats;
