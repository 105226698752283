import { faChevronRight, faLock, faLockOpen, faPersonChalkboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType } from "components/Button/Button";
import ContactDetails from "components/ContactDetails/ContactDetails";
import { DataFetcher } from "components/DataFetcher";
import { useModal } from "components/Modal/context/ModalContext";
import PercentageCircle from "components/PercentageCircle/PercentageCircle";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModuleStateType } from "utils/constants";
import "./SubModuleAccordion.scss";
import MediaType from "components/MediaType/MediaType";

const SubModuleAccordion = ({ expanded, courseId, moduleId, subModuleCount, guruUrl, webcast, contactNumber, state, handleOpenLockModal }) => {
	const [toggle, setToggle] = useState(expanded);
	const [subModules, setSubModules] = useState([]);
	const [height, setHeight] = useState("0");
	const panelRef = useRef(null);
	const navigate = useNavigate();
	const [fetchData, setFetchData] = useState({});

	const togglePanel = () => {
		setToggle(!toggle);
	};

	useEffect(() => {
		if (panelRef.current) {
			if (toggle) {
				if (subModules.length === 0) {
					setFetchData({ url: "/api/SubModules/ByModuleId/" + moduleId });
				}
				else {
					setHeight(`${panelRef.current.scrollHeight}px`);
				}
			}
			else {
				setHeight("0");
			}
		}
	}, [toggle, subModules, moduleId]);

	const handleSetData = useCallback((data) => {
		if (subModules.length === 0) {
			data.SubContent.sort((x, y) => { return x.Position - y.Position });
			setSubModules(data.SubContent);
			if (toggle && panelRef.current) {
				setHeight(`${panelRef.current.scrollHeight}px`);
			}
		}
	}, [toggle, subModules]);

	const handleClick = (subModule) => {
		navigate(`/player#courseId-${courseId}-moduleId-${moduleId}-subModuleId-${subModule.Id}`);
	};

	const { openModal } = useModal();

	const modalTitle = "Unlock Modules";
	const modalBasicButton = true;

	const modalContent = (
		<div>
			<p>This module is part of a package you don't have access to. Contact us to ask about getting access for your organisation on:</p>
			<p style={{ display: 'flex', justifyContent: 'center' }}><ContactDetails /></p>
		</div>
	);

	const handleOpenModal = () => {
		openModal(modalTitle, modalContent, modalBasicButton);
	};

	const getProgressString = (subModule) => {
		if (subModule.Progress === 100) {
			return (subModule.HasProgress) ? "Completed" : "Watched";
		}
		if (subModule.Progress === 0) {
			return "Not started";
		}
		return "In progress";
	}

	const getExtraText = () => {
		let message = (!webcast)
			? "Need expert help with a part of your phone system? Book a free 1 to 1 guru session with us."
			: `Need expert help with this topic? Book a free 1 to 1 Guru session or call us on ${contactNumber} to schedule a webcast.`;
		return (
			<div className="extra-text">
				<h3>Still searching for what you need?</h3>
				<p>{message}</p>
			</div>
		);
	}

	if (subModuleCount === 0) {
		return null;
	}

	return (
		<div className="list-accordion">
			<DataFetcher fetchData={fetchData} setData={handleSetData}>
			<button className="accordionToggle" onClick={togglePanel}>
				<span className="button-text">See {subModuleCount} Sub-Modules</span>
				<span className="button-arrow">
					<span className={`button-arrow-icon ${toggle ? "toggled" : ""}`}>
						<FontAwesomeIcon icon={faChevronRight} />
					</span>
				</span>
			</button>
			<div
				ref={panelRef}
				className="list-accordion-panel"
				style={{ height: height, transition: "height 0.8s ease" }}
			>
				<h3>Sub-Modules</h3>
				{subModules.map((subModule, index) => (
					<div className="sub-module" key={index}>
						{state === ModuleStateType.Enabled &&
							<div className="sub-module-progress" onClick={e => handleClick(subModule)}>
								<PercentageCircle progress={subModule.Progress} />
								<p className="small-text">{getProgressString(subModule)}</p>
							</div>
						}
						{state === ModuleStateType.DisabledUpsell &&
							<div className="sub-module-progress locked" onClick={handleOpenModal}>
								<div className="locked-icon" ><FontAwesomeIcon icon={faLock} /></div>
								<p className="small-text">Locked</p>
							</div>
						}
						<div className="sub-module-details">
							<h4>{subModule.Name}</h4>
							<MediaType type={subModule.ContentType} />
						</div>
					</div>
				))}
				{guruUrl.length > 0 && (
					<div className="extra">
						<div className="extra-icon">
							<FontAwesomeIcon icon={faPersonChalkboard} className="fa-2x" />
						</div>
						{getExtraText()}
						<div className="extra-link">
							<Button type={ButtonType.primary} text="Book Now" to={guruUrl} newTab />
						</div>
					</div>
				)}
				{state === ModuleStateType.DisabledUpsell && (
					<div className="extra">
						<div className="extra-icon">
							<FontAwesomeIcon icon={faLockOpen} className="fa-2x" />
						</div>
						<div className="extra-text">
							<h3 className="extra-text-alone">Want to Unlock this Content?</h3>
						</div>
						<div className="extra-link">
							<Button type={ButtonType.Secondary} onClick={handleOpenLockModal} text="Unlock Now" />
						</div>
					</div>
				)}
				</div>
			</DataFetcher>
		</div>
	);
};

export default SubModuleAccordion;
