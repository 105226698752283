import { Button } from "components/Button/Button";
import "./CourseItem.scss";
import ModuleItem from "./ModuleItem";

const CourseItem = ({ course, expandedModuleId, contactNumber }) => {
	return (
		<div className="list-view-item" id={"courseId-" + course.Id}>
			<div className="list-view-item-details">
				<div className="list-view-item-details-text">
					<h2>{course.Name}</h2>
					<p>{course.Description}</p>
				</div>
				<div className="list-view-item-details-image">
					<img alt={course.Name} src={course.ImageUri} />
				</div>
			</div>
			<div className="list-view-item-continue">
				<p><strong>Last Viewed:</strong> {course.LastViewed.SubContentName}</p>
				<Button text="Continue" to={`/player#courseId-${course.Id}-moduleId-${course.LastViewed.ContentId}-subModuleId-${course.LastViewed.SubContentId}`} />
			</div>
			<h3>Modules</h3>
			{course.SubContent.map(module => (
				<ModuleItem key={module.Id} course={course} module={module} expandedModuleId={expandedModuleId} contactNumber={contactNumber} />
			))}
		</div>
	);
};

export default CourseItem;