import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getFetchOptions } from "utils/fetch";
import QuickSearch from "../QuickSearch/QuickSearch";
import SearchResultsList from "./components/SearchResultsList";

const SearchResults = () => {
	const { state: searchTerm } = useLocation();
	const { gradient, watermark } = useBranding();
	const { execute, executing } = useFetch();
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		const options = getFetchOptions({
			method: "POST",
			body: searchTerm
		});
		execute("api/Search/", options, (content, error) => {
			setSearchResults(content);
		});
	}, [searchTerm]);	
	
	if ((executing) || (!searchResults)) {
		return <LoadingSpinner />;
	}

	return (
		<div className="grid-two-col gutters">
			<Pod title="Search Results" gradient={gradient} watermark={watermark}>
				<SearchResultsList searchTerm={searchTerm} searchResults={searchResults}></SearchResultsList>
			</Pod>
			<Pod title="Search" gradient={gradient} watermark={watermark} mobileOrder={2} sticky>
				<QuickSearch />
			</Pod>
		</div>
	);
};

export default SearchResults;
