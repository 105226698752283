import { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import "./DateRangeInput.scss";
import "components/DatePicker.scss";

const DateRangeType = {
	OneDay: 0,
	SevenDays: 1,
	OneMonth: 2,
	TwelveMonths: 3
};

const DateRangeInput = ({ earliestDataDate, setRange, clear }) => {
	const allRanges = [
		{ id: DateRangeType.OneDay, value: "1 day" },
		{ id: DateRangeType.SevenDays, value: "7 days" },
		{ id: DateRangeType.OneMonth, value: "1 month" },
		{ id: DateRangeType.TwelveMonths, value: "12 months" }
	];

	const [selectedRange, setSelectedRange] = useState(allRanges[0]);
	const [selectedTo, setSelectedTo] = useState(new Date());

	const minDate = new Date(earliestDataDate);
	const maxDate = new Date();

	const getFromDate = (range, to) => {
		let from = new Date(to.getFullYear(), to.getMonth(), to.getDate());
		switch (range) {
			case DateRangeType.SevenDays:
				from = new Date(from - 86400000 * 6);
				break;
			case DateRangeType.OneMonth:
				from.setMonth(from.getMonth() - 1);
				break;
			case DateRangeType.TwelveMonths:
				from.setFullYear(from.getFullYear() - 1);
				break;
			default:
				/* DO NOTHING */
				break;
		}
		return from;
	}

	const handleChangeRange = (value) => {
		const index = allRanges.findIndex((curr) => {
			return curr.id === Number(value);
		});
		if (index !== -1) {
			setSelectedRange(allRanges[index]);
		}
		const from = getFromDate(Number(value), selectedTo);
		setRange({ from: from, to: selectedTo });
	};

	const handleChangeToDate = (value) => {
		let toAtEndOfDay = value.to;
		toAtEndOfDay = new Date(toAtEndOfDay.setDate(value.to.getDate() + 1) - 1);
		setSelectedTo(toAtEndOfDay);

		const from = getFromDate(selectedRange.id, toAtEndOfDay);
		setRange({ from: from, to: toAtEndOfDay });
	};

	// Effect to force an initial report generation for the default settings (1 day to today).
	useEffect(() => {
		if (!earliestDataDate) {
			const from = getFromDate(selectedRange.id, new Date());
			setRange({ from: from, to: new Date() });
		}
	}, [earliestDataDate, selectedRange.id, setRange]);

	return (
		<div className="timeline-range-input">
			<h3>Get activities for</h3>
			<select name="ranges" className="timeline-range-type-select" onChange={(e) => handleChangeRange(e.target.value)}>
				{allRanges.map(item => <option key={item.id} value={item.id}>{item.value}</option>)}
			</select>
			<h3>to the end of</h3>
			<Calendar
				className="timeline-range-from-date"
				dateFormat="dd/mm/y"
				minDate={minDate}
				maxDate={maxDate}
				value={selectedTo}
				onChange={(e) => handleChangeToDate({ to: e.value })}
				showIcon={true}
			/>
		</div>
	);
};

export default DateRangeInput;