import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./ContactDetails.scss";

const ContactDetails = ({ number = "01244 670 200", email = "support@ucskills.com" }) => {
	return (
		<div className="contactDetails">
			<FontAwesomeIcon icon={faPhone} className="fa-2x" />
			<h3>{number}</h3>
			<FontAwesomeIcon icon={faEnvelope} className="fa-2x" />
			<h3>{email}</h3>
		</div>
	);
};

export default ContactDetails;
