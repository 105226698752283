import { useEffect, useState } from 'react';
import "./PlayerMedia.scss";
import { PackageType, packageIsScorm } from "features/Shared/ContentData/PackageType";
import { BreadcrumbItem } from 'reactstrap';

const PlayerMedia = ({ data, mediaRef, uri, pathIds, title, handleMediaLoaded, calculatedPlayerHeight, maxPlayerHeight }) => {	
	const getUri = () => {
		if (!packageIsScorm(data.Type)) {
			return getXapiUri();
		}
		return getScormUri();
	}

	const getScormUri = () => {
		// ucSKILLS 2 passes a height, but this appears to be overriden by the #scorm-frame iframe in the scormframe.html anyway...
		const scormParameters = {
			src: data.Path + data.LaunchPage,
			version: data.ScormData.Version,
			data: data.ScormData.CmiData,
			log: data.ScormData.LogApi
		};
		return data.ScormData.ProxyUri + "#" + encodeURIComponent(JSON.stringify(scormParameters));
	}

	const getXapiUri = () => {
		let result = data.Path + data.LaunchPage;
		const actorParam = JSON.stringify({
			"account": {
				"homePage": "https://iteluk.com",
				"name": btoa(data.PathIds)
			}
		});
		// Ensure the entire actor parameter is URI-encoded
		result += "&actor=" + encodeURIComponent(actorParam);

		console.debug(result);
		return result;
	}

	return (
		<div className="player-media" style={{ height: calculatedPlayerHeight }}>
			<div className="player-media-container">
				<iframe id="player-content" ref={mediaRef} title={title} src={getUri()} onLoad={handleMediaLoaded} allowFullScreen></iframe>
			</div>
		</div>
	);
};

export default PlayerMedia;
