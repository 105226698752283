import { useState, useEffect } from 'react';
import './Pod.scss';
import waterfall from "../../assets/images/waterfall.svg";

const Pod = ({ children, title = "title", gradient = true, watermark, mobileOrder, sticky }) => {
	let watermarkSource = waterfall;
	if (watermark) {
		if (watermark.src) {
			watermarkSource = watermark.src;
		} else if (!watermark.revertToDefault) {
			watermarkSource = null;
		}
	}

	const mobileWidth = 1024;
	const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

	const handleResize = () => {
		setIsMobile(window.innerWidth < mobileWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const mobileOrderStyle = isMobile && mobileOrder ? { gridRow: mobileOrder } : {};

	return (
		<div className={`pod ${sticky ? 'sticky' : ''}`} style={mobileOrderStyle}>
			<div className="podHeader">
				<h1>{title}</h1>
				{watermarkSource && <img alt="Pod Background Detail" className="podHeader-image" src={watermarkSource} />}
				{gradient && <span className="podHeader-grad"></span>}
			</div>
			<div className="podBody">
				{children}
			</div>
		</div>
	);
};

export default Pod;
