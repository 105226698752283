import React from 'react';
import { Button, ButtonType } from '../Button/Button';
import './ListNavigation.scss';

const ListNavigation = ({ items = [] }) => {
	return (
		<div className="list-navigation">
			<div className="list-navigation-item">
				<h2>Your Courses</h2>
				{items.map(item => (
					<Button key={item.Id} text={item.Name} type={ButtonType.Secondary} to={`#courseId-${item.Id}`} />
				))}
			</div>
		</div>
	);
};

export default ListNavigation;
