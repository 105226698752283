import { useState, useEffect, useCallback, useRef } from 'react';
import './ModalContent.scss';
import { Button, ButtonType } from 'components/Button/Button';
import { getFetchOptions } from 'utils/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { DataFetcher } from '../../../../components/DataFetcher';

const ModalContent = ({ edit, companyId, users = [], onClose }) => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		username: '',
		roleId: 0,
		contentGroups: [],
		deviceGroup: null,
	});
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const [initialUsers, setInitialUsers] = useState([]);
	const [updatedUsers, setUpdatedUsers] = useState([]);
	const [finalUsers, setFinalUsers] = useState();
	const [deviceGroupUserCount, setDeviceGroupUserCount] = useState({});
	const [contentGroupUserCount, setContentGroupUserCount] = useState({});
	const [disableDelete, setDisableDelete] = useState(false);
	const [confirmation, setConfirmation] = useState({ action: null, visible: false });
	const [canSave, setCanSave] = useState(false); // Track if Save button should be enabled
	const [data, setData] = useState(null);
	const [fetchData, setFetchData] = useState({});
	const [fetchStep, setFetchStep] = useState(1);

	const contentGroupOptions = data?.ContentGroupData ?? [];
	const deviceGroupOptions = data?.DeviceGroupData || [];

	const ActionTypes = {
		DELETE: 'delete',
		RESEND: 'resend',
		UPDATE: 'update',
		ADD: 'add',
	};

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	useEffect(() => {
		setFetchStep(1);
	}, []);

	useEffect(() => {
		const handleFetchStep = () => {
			let options;
			switch (fetchStep) {
				case 1:
					setFetchRequestData(`/api/CompanyUser/CreationPrerequisites/${companyId}/-99`);
					break;
				case 2:
					const selectedIds = users.map(user => user.Id);
					options = getFetchOptions({
						method: "DELETE",
						body: selectedIds
					});
					setFetchRequestData("/api/CompanyUser", options);
					break;
				case 3:
					const selectedUsernames = users.map(user => user.Username);
					options = getFetchOptions({
						method: "POST",
						body: selectedUsernames
					});
					setFetchRequestData("/api/ResendCompleteRegistration", options);
					break;
				case 4: // Update
					options = getFetchOptions({
						method: "PUT",
						body: {
							Users: finalUsers
						}
					});
					setFetchRequestData("/api/CompanyUser", options);
					break;
				case 5: // Add
					options = getFetchOptions({
						method: "PUT",
						body: {
							SingleUser: {
								Id: -1,
								CompanyId: companyId,
								FirstName: formData.firstName,
								LastName: formData.lastName,
								Username: formData.username,
								RoleId: formData.roleId,
								CreatedOnRegistration: false,
								Active: true,
								ContentGroups: formData.contentGroups
							}
						}
					});
					setFetchRequestData("/api/CompanyUser", options);
					break;
				case 6:
					if (onClose) {
						onClose();
					}
					break;
				default:
					break;
			}
		};
		handleFetchStep();
	}, [fetchStep, setFetchRequestData]);

	useEffect(() => {
		if (data && !data.ContentGroupData) {
			setFetchStep(6);
		}
	}, [data]);

	useEffect(() => {
		if (edit && users.length > 0) {
			setInitialUsers(users.map(user => ({ ...user })));
			setUpdatedUsers(users.map(user => ({ ...user })));
			if (users.length === 1) {
				const user = users[0];
				setFormData({
					firstName: user.FullName ? user.FullName.split(' ')[0] : '',
					lastName: user.LastName || '',
					username: user.Username || '',
					roleId: user.RoleId || 0,
					contentGroups: user.ContentGroups
						.filter(group => group.Id && group.Name) // Filter out invalid groups
						.map(group => ({ Id: group.Id, Name: group.Name })) || [],
					deviceGroup: user.DeviceGroups?.[0] || null
				});
			} else {
				const accessLevels = users.map(user => user.RoleId);
				const uniqueAccessLevels = [...new Set(accessLevels)];

				const mixedAccessLevel = (uniqueAccessLevels.length === 1) ? uniqueAccessLevels[0] : -1;

				const uniqueDeviceGroups = users
					.map(user => user.DeviceGroups[0]?.Id)
					.every(id => id === users[0].DeviceGroups[0]?.Id);

				const contentGroupCount = users.reduce((acc, user) => {
					user.ContentGroups.forEach(group => {
						acc[group.Id] = (acc[group.Id] || 0) + 1;
					});
					return acc;
				}, {});

				const updatedContentGroups = contentGroupOptions.map(group => {
					const userCount = contentGroupCount[group.Id] || 0;
					if (userCount === users.length) {
						// Fully selected
						return { Id: group.Id, Name: group.Name };
					} else if (userCount > 0) {
						// Partially selected
						return { Id: group.Id, Name: group.Name, mixed: true };
					}
					// Not selected
					return null;
				}).filter(Boolean);

				setFormData({
					firstName: '',
					lastName: '',
					username: '',
					roleId: mixedAccessLevel,
					contentGroups: updatedContentGroups,
					deviceGroup: uniqueDeviceGroups ? users[0].DeviceGroups?.[0] : null,
				});
			}
		} else if (!edit && data?.RoleData?.length > 0 && formData.roleId === 0) {
			// Only update the formData if it's not already set
			setFormData(prevData => ({
				...prevData,
				roleId: data.RoleData[0].Id,
				contentGroups: []
			}));
		}

		const groupCount = users.reduce((acc, user) => {
			const deviceGroup = user.DeviceGroups?.[0]?.Id;
			if (deviceGroup) {
				acc[deviceGroup] = (acc[deviceGroup] || 0) + 1;
			}
			return acc;
		}, {});
		setDeviceGroupUserCount(groupCount);

		const contentGroupCount = users.reduce((acc, user) => {
			user.ContentGroups.forEach(group => {
				acc[group.Id] = (acc[group.Id] || 0) + 1;
			});
			return acc;
		}, {});
		setContentGroupUserCount(contentGroupCount);

		if (edit) {
			const updatedContentGroups = contentGroupOptions.map(group => {
				const userCount = contentGroupCount[group.Id] || 0;
				if (userCount === users.length) {
					return { Id: group.Id, Name: group.Name };
				} else if (userCount > 0) {
					return { Id: group.Id, Name: group.Name, mixed: true };
				}
				// Not selected
				return null;
			}).filter(Boolean);

			setFormData(prevData => ({
				...prevData,
				contentGroups: updatedContentGroups
			}));
		}

		// Check if any user has IsSelf true, and disable delete button if so
		const isSelfPresent = users.some(user => user.IsSelf);
		setDisableDelete(isSelfPresent); // Set disableDelete state based on IsSelf
	}, [users, edit, data, formData.roleId]);

	useEffect(() => {
		// Check if all required fields are filled when not in edit mode
		if (!edit) {
			const { firstName, lastName, username, roleId } = formData;
			setCanSave(!!firstName && !!lastName && !!username && roleId > 0);
		}
	}, [formData, edit]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'roleId' && formData.roleId === -1) {
			return;
		}
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleCheckboxChange = (e, group, groupType) => {
		const { checked } = e.target;
		setFormData((prevData) => {
			let updatedGroups;
			if (checked) {
				updatedGroups = [...prevData[groupType], { Id: group.Id, Name: group.Name }];
			} else {
				updatedGroups = prevData[groupType].filter(g => g.Id !== group.Id);
			}
			return { ...prevData, [groupType]: updatedGroups };
		});
	};

	const handleRadioChange = (e, group) => {
		setFormData((prevData) => ({
			...prevData,
			deviceGroup: { Id: group.Id, Name: group.Name }
		}));
	};

	const handleIconClick = (group) => {
		alert(`Device Group ID: ${group.Id}, Name: ${group.Name}`);
	};

	const handleDeleteClick = () => {
		setConfirmation({ action: ActionTypes.DELETE, visible: true });
	};

	const handleResendClick = () => {
		setConfirmation({ action: ActionTypes.RESEND, visible: true });
	};

	const handleSubmitClick = () => {
		if (firstNameRef.current) {
			firstNameRef.current.blur();
		}
		if (lastNameRef.current) {
			lastNameRef.current.blur();
		}
		setConfirmation({ action: edit ? ActionTypes.UPDATE : ActionTypes.ADD, visible: true });
	};

	const handleConfirm = () => {
		if (confirmation.action === ActionTypes.DELETE) { //fetch step 2
			console.debug("Delete confirmed for the following users:");
			users.forEach(user => {
				console.debug(`User ID: ${user.Id}, Name: ${user.FullName}`);
			});
			setFetchStep(2);
		} else if (confirmation.action === ActionTypes.RESEND) { // fetch step 3
			console.debug("Resend registration email confirmed for the following users:");
			users.forEach(user => {
				console.debug(`User ID: ${user.Id}, Name: ${user.FullName}`);
			});
			setFetchStep(3);
		} else if (confirmation.action === ActionTypes.UPDATE) { // fetch step 4
			setFinalUsers(updatedUsers.map(user => {
				const updatedUser = { ...user };

				updatedUser.FirstName = formData.firstName;
				updatedUser.LastName = formData.lastName;
				updatedUser.Username = formData.username;
				updatedUser.RoleId = formData.roleId;

				const initialUser = initialUsers.find(u => u.Id === user.Id);
				const initialContentGroups = initialUser.ContentGroups.map(group => group.GroupName);
				updatedUser.ContentGroups = initialUser.ContentGroups.filter(group =>
					!contentGroupOptions.includes(group.Name) || formData.contentGroups.some(fg => fg.Name === group.Name)
				);

				formData.contentGroups.forEach(group => {
					if (group.Name && !initialContentGroups.includes(group.Name)) {
						updatedUser.ContentGroups.push({ GroupId: group.Id, GroupName: group.Name });
					}
				});

				return {
					Id: updatedUser.Id,
					CompanyId: companyId,
					FirstName: updatedUser.FirstName,
					LastName: updatedUser.LastName,
					Username: updatedUser.Username,
					RoleId: formData.roleId === -1 ? updatedUser.RoleId : formData.roleId,
					CreatedOnRegistration: false,
					Active: true,
					ContentGroups: updatedUser.ContentGroups
				};
			}));

			console.debug("Update confirmed for the following users:");
			users.forEach(user => {
				console.debug(`User ID: ${user.Id}, Name: ${user.FullName}`);
			});
			setFetchStep(4);
		} else if (confirmation.action === ActionTypes.ADD) { // fetch step 5
			console.debug("Add confirmed for the following user:");
			console.debug(`First Name: ${formData.firstName}, Last Name: ${formData.lastName}, Email: ${formData.username}`);
			setFetchStep(5);
		}
		setConfirmation({ action: null, visible: false });
	};

	const handleCancel = () => {
		setConfirmation({ action: null, visible: false });
	};

	const contentGroupsClass = contentGroupOptions.length > 5 ? "overflowed" : "";
	const deviceGroupsClass = deviceGroupOptions.length > 5 ? "overflowed" : "";

	const getDeleteButtonText = () => {
		if (disableDelete) {
			return "You cannot delete yourself";
		}
		return users.length === 1 ? "Delete User" : `Delete ${users.length} Users`;
	};

	return (
		<>
			{confirmation.visible ? (
				<>
					<div className="confirmation-message">
						<p>
							{confirmation.action === ActionTypes.DELETE && (
								<>Are you sure you want to <span>delete</span> the following users?</>
							)}
							{confirmation.action === ActionTypes.RESEND && (
								<>Are you sure you want to <span>resend the registration email</span> to the following users?</>
							)}
							{confirmation.action === ActionTypes.UPDATE && (
								<>Are you sure you want to <span>update</span> the following users?</>
							)}
							{confirmation.action === ActionTypes.ADD && (
								<>Are you sure you want to <span>add</span> the following user?</>
							)}
						</p>

						<ul className="delete-user-list">
							{confirmation.action !== ActionTypes.ADD ? (
								users.map(user => (
									<li key={user.Id} className="delete-user-list-item">{user.FullName}</li>
								))
							) : (
								<li className="delete-user-list-item">
									{formData.firstName} {formData.lastName} ({formData.username})
								</li>
							)}
						</ul>
					</div>
					<div className="modal-buttons">
						<Button type={ButtonType.Primary} text="Yes" onClick={handleConfirm} />
						<Button type={ButtonType.Secondary} text="No" onClick={handleCancel} />
					</div>
				</>
			) : (
				<div className="user-management-modal">
					<DataFetcher fetchData={fetchData} setData={setData} />
					<form>
						<div className="user-management-modal-grid">
							{users.length < 2 ? (
								<>
									<div>
										<label>
											<h4>First Name</h4>
											<input
												type="text"
												name="firstName"
												value={formData.firstName}
												onChange={handleChange}
												ref={firstNameRef}
												disabled={edit && users.length > 1}
												required={!edit}
												placeholder={edit && users.length > 1 ? '-' : ''}
											/>
										</label>
									</div>
									<div>
										<label>
											<h4>Last Name</h4>
											<input
												type="text"
												name="lastName"
												value={formData.lastName}
												onChange={handleChange}
												ref={lastNameRef}
												disabled={edit && users.length > 1}
												required={!edit}
												placeholder={edit && users.length > 1 ? '-' : ''}
											/>
										</label>
									</div>
									<div>
										<label>
											<h4>Email</h4>
											<input
												type="email"
												name="username"
												value={formData.username}
												onChange={handleChange}
												disabled={edit}
												required={!edit}
												placeholder={edit && users.length > 1 ? '-' : ''}
											/>
										</label>
									</div>
								</>
							) : (
								<div>
									<h4>Selected Users</h4>
									<ul className="delete-user-list">
										{users.map(user => (
											<li key={user.Id} className="delete-user-list-item">{user.FullName}</li>
										))}
									</ul>
								</div>
							)}
							<div>
								<label>
									<h4>Access Level</h4>
									<select
										name="roleId"
										value={formData.roleId}
										onChange={handleChange}
										required
										disabled={formData.roleId === -1}
									>
										{data?.RoleData?.map(role => (
											<option key={role.Id} value={role.Id}>{role.Name}</option>
										))}
										{formData.roleId === -1 && (
											<option value={-1} disabled>Mixed</option>
										)}
									</select>
								</label>
							</div>
							<div className="user-management-modal-grid-colOneSpanTwo checkbox-list">
								<label><h4>Content Groups</h4></label>
								<div className={contentGroupsClass}>
									{contentGroupOptions.map(group => {
										const isPresentForSome = users.some(user => (user.ContentGroups || []).map(g => g.Id).includes(group.Id));
										const isPresentForAll = users.every(user => (user.ContentGroups || []).map(g => g.Id).includes(group.Id));
										const isMixed = isPresentForSome && !isPresentForAll;
										const isChecked = formData.contentGroups.some(g => g.Id === group.Id);

										return (
											<div key={group.Id}>
												<label>
													<p className={`contentGroup ${isMixed ? 'mixed' : ''}`}>
														<input
															type="checkbox"
															name={group.Name}
															checked={isChecked}
															onChange={(e) => handleCheckboxChange(e, group, 'contentGroups')}
														/>
														{group.Name} {contentGroupUserCount[group.Id] > 0 && <>({contentGroupUserCount[group.Id]} users)</>}
													</p>
												</label>
											</div>
										);
									})}
								</div>
							</div>
							{deviceGroupOptions.length > 0 && (
								<div className="user-management-modal-grid-colTwoSpanTwo checkbox-list">
									<label><h4>Device Groups</h4></label>
									<div className={deviceGroupsClass}>
										{deviceGroupOptions.map(group => {
											const isChecked = formData.deviceGroup?.Id === group.Id;
											const userCount = deviceGroupUserCount[group.Id] || 0;

											return (
												<div key={group.Id}>
													<label>
														<p className="contentGroup">
															<input
																type="radio"
																name="deviceGroup"
																checked={isChecked}
																onChange={(e) => handleRadioChange(e, group)}
															/>
															{group.Name} {userCount > 0 && <>({userCount} users)</>}
															<FontAwesomeIcon
																icon={faPenToSquare}
																onClick={() => handleIconClick(group)}
																className="edit-icon"
																style={{ marginLeft: '10px', cursor: 'pointer' }}
															/>
														</p>
													</label>
												</div>
											);
										})}
									</div>
									<Button type={ButtonType.Tertiary} text="Add New Device Group" to="/deviceselection" />
								</div>
							)}
						</div>
					</form>
				</div>
			)}
			{!confirmation.visible && (
				<div className="modal-buttons">
					{edit && (
						<>
							<Button
								type={ButtonType.Delete}
								text={getDeleteButtonText()}
								onClick={handleDeleteClick}
								disabled={disableDelete}
							/>
							<Button type={ButtonType.Secondary} text="Resend Registration" onClick={handleResendClick} />
						</>
					)}
					<Button
						type={ButtonType.Primary}
						text="Save"
						onClick={handleSubmitClick}
						disabled={!canSave && !edit}
					/>
				</div>
			)}
		</>
	);
};

export default ModalContent;
