import { Button, ButtonType } from "components/Button/Button";
import "./ModuleItem.scss";
import PercentageCircle from "components/PercentageCircle/PercentageCircle";
import MediaType from "components/MediaType/MediaType";
import SubModuleAccordion from "./SubModuleAccordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useModal } from 'components/Modal/context/ModalContext';
import ContactDetails from 'components/ContactDetails/ContactDetails';
import { ModuleStateType } from 'utils/constants';
import { useNavigate } from "react-router-dom";

const ModuleItem = ({ course, module, expandedModuleId, contactNumber }) => {
	const { openModal } = useModal();
	const navigate = useNavigate();

	const modalTitle = "Unlock Modules";
	const modalBasicButton = true;

	const modalContent = (
		<div>
			<p>This module is part of a package you don't have access to. Contact us to ask about getting access for your organisation on:</p>
			<p style={{ display: 'flex', justifyContent: 'center' }}><ContactDetails /></p>
		</div>
	);

	const handleOpenLockModal = () => {
		openModal(modalTitle, modalContent, modalBasicButton);
	};

	const handleClick = () => {
		navigate(`/player#courseId-${course.Id}-moduleId-${module.Id}-subModuleId-${module.LastViewed.Id}`);
	};

	return (
		<div className="list-view-item-item" id={`courseId-${course.Id}-moduleId-${module.Id}`}>
			<div className="list-view-item-item-progress">
				{module.State === ModuleStateType.Enabled &&
					<div className="list-view-item-item-progress-container" onClick={handleClick}>
						<PercentageCircle progress={module.Progress} />
						<p className="small-text">{module.Progress === 100 ? "Complete" : module.Progress === 0 ? "Not started" : `${module.Progress}%`}</p>
					</div>
				}
				{module.State === ModuleStateType.DisabledUpsell &&
					<div className="list-view-item-item-progress-container locked">
						<div className="locked-icon" onClick={handleOpenLockModal}><FontAwesomeIcon icon={faLock} /></div>
						<p className="small-text">Locked</p>
					</div>
				}
			</div>
			<div className="list-view-item-item-details">
				<div className="list-view-item-item-details-topline">
					<h3>{module.Name}</h3>
					{module.Duration && <p className="small-text">({module.Duration})</p>}
					<MediaType type={module.ContentType} />
				</div>
				<p className="list-view-item-item-details-description">{module.Description}</p>
				{module.State === ModuleStateType.Enabled && <Button type={ButtonType.Primary} text={module.Progress ? "Continue" : "Start"} onClick={handleClick} />}
				{module.State === ModuleStateType.DisabledUpsell && <Button type={ButtonType.Secondary} text={"Unlock"} onClick={handleOpenLockModal} />}
				<SubModuleAccordion
					expanded={module.Id === expandedModuleId}
					courseId={course.Id}
					moduleId={module.Id}
					subModuleCount={module.SubSubContentCount}
					guruUrl={module.GuruUrl}
					webcast={module.HasWebcast}
					contactNumber={contactNumber}
					state={module.State}
					handleOpenLockModal={handleOpenLockModal}
				/>
			</div>
		</div>
	);
};

export default ModuleItem;
