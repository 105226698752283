import { DataFetcher } from "components/DataFetcher";
import ListNavigation from "components/ListNavigation/ListNavigation";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./CourseView.scss";
import CourseItem from "./components/CourseItem";

const CourseView = () => {
	const { hash } = useLocation();
	const { gradient, watermark } = useBranding();
	const [expandedModuleId, setExpandedModuleId] = useState(-1);
	const [fetchData, setFetchData] = useState({});
	const [fetchStep, setFetchStep] = useState(0);
	const [data, setData] = useState(null);
	const [contactNumber, setContactNumber] = useState("");
	const [courseAndModuleData, setCourseAndModuleData] = useState(null);
	
	useEffect(() => {
		setFetchStep(1);
	}, []);

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	useEffect(() => {
		if (fetchStep === 1) {
			setFetchRequestData("/api/ContactDetails");
		}
		else {
			setFetchRequestData("/api/CoursesAndModules/ByCurrentUser");
		}
	}, [fetchStep, setFetchRequestData]);
	
	// Effect for processing the returned data from the DataFetcher.
	useEffect(() => {
		if (data) {
			if (data.ContactNo && !contactNumber.length) {
				setContactNumber(data.ContactNo);
				setFetchStep(2);
			}
			else if (!courseAndModuleData) {
				setCourseAndModuleData(data);
				setFetchStep(0);
			}
		}
	}, [data]);

	useEffect(() => {
		if (hash) {
			const elementId = hash.slice(1);
			const MODULE_ID_TEXT = "moduleId";

			let moduleIdIndex = elementId.indexOf(MODULE_ID_TEXT);
			if (moduleIdIndex !== -1) {
				const moduleId = Number(elementId.substring(moduleIdIndex + MODULE_ID_TEXT.length + 1));
				setExpandedModuleId(moduleId);
			}

			const scrollToElement = () => {
				const element = document.getElementById(elementId);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
					return true;
				}
				return false;
			};

			if (!scrollToElement()) {
				const observer = new MutationObserver(() => {
					if (scrollToElement()) {
						observer.disconnect();
					}
				});
				observer.observe(document.body, { childList: true, subtree: true });

				return () => {
					if (observer) {
						observer.disconnect();
					}
				};
			}
		}
	}, [hash]);

	return (
		<DataFetcher fetchData={fetchData} setData={setData}>
			<div className="grid-two-col gutters">
				<Pod title="Courses" gradient={gradient} watermark={watermark}>
					{courseAndModuleData && (<div className="list-view">
						{courseAndModuleData.map(course => (
							<CourseItem key={course.Id} course={course} expandedModuleId={expandedModuleId} contactNumber={contactNumber} />
						))}
					</div>
					)}
				</Pod>
				<Pod title="Course Navigation" gradient={gradient} watermark={watermark} mobileOrder={1} sticky>
					{courseAndModuleData && (<ListNavigation items={courseAndModuleData} />)}
				</Pod>
			</div>
		</DataFetcher>
	)
};

export default CourseView;
