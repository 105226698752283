import YealinkImage from '../../../assets/images/devices/yealink.webp';
import CiscoImage from '../../../assets/images/devices/cisco.webp';
import AvayaImage from '../../../assets/images/devices/avaya.webp';

const DeviceImages = {
	101: {
		src: YealinkImage,
		alt: 'Yealink device',
	},
	102: {
		src: CiscoImage,
		alt: 'Cisco device',
	},
	103: {
		src: AvayaImage,
		alt: 'Avaya device',
	},
};

export default DeviceImages;