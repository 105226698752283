import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import useFetch from "hooks/useFetch";
import useLogout from "hooks/useLogout";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleModal from "./Modal/SimpleModal";
import useUserData from "hooks/useUserData";

export const DataFetcher = ({ fetchData, setData, setError = null, children }) => {
	const { executing, execute } = useFetch();
	const [forbiddenError, setForbiddenError] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();
	const { logout } = useLogout(navigate);
	const { username } = useUserData();

	// Effect that handles the error logic; other errors (301 - 402, 404+) are not yet handled.
	const handleError = useCallback((error) => {
		if (error) {
			let allowErrorProcessing = true;
			if (error.Status === 403) {
				const forbiddenMessageMap = {
					"ERR_AUTHORIZATION_MISSING": "",
					"ERR_AUTHORIZATION_SCHEME": "",
					"ERR_SESSION_NO_IDENTITY": "",
					"ERR_SESSION_NOT_LOGGED_IN": "",
					"ERR_SESSION_LOGIN_NEW_LOCATION": "",
					"ERR_SESSION_TIMEOUT": " due to a long period of inactivity",
					"ERR_LOGIN_TIMEOUT": " due to a long period of inactivity"
				};
				if (error.Message in forbiddenMessageMap) {
					const prefix = "You will be redirected to the login page";
					const suffix = ". You will be returned to this page after logging in.";
					setForbiddenError({
						Status: error.Status,
						Message: (<div>{prefix + forbiddenMessageMap[error.Message] + suffix}</div>)
					});
					allowErrorProcessing = false;
				}
				else if (error.Message === "ERR_UNAUTHORIZED_LOGOUT") {
					allowErrorProcessing = false;
					navigate(-1);
				}
			}
			if (allowErrorProcessing && setError) {
				setError(error);
			}
		}
	}, [navigate, setError]);

	// A warning for missing "fetchData" object occurs; objects in useEffect dependencies cause infinite loops.
	useEffect(() => {
		if (fetchData && fetchData.url) {
			setForbiddenError(null);
			execute(fetchData.url, fetchData.options, (fetchedData, error) => {
				if (!error) {
					setData(fetchedData);
				}
				else {
					handleError(error);
				}
			});
		}
	}, [fetchData.url, fetchData.options, execute, setData, handleError]);
	
	const handleCloseModal = () => {
		if (forbiddenError) {
			const key = "ucs-lau-" + (username ? window.btoa(username) : "anon");
			localStorage.setItem(key, location.pathname + location.search + location.hash);
			setForbiddenError(null);
			logout();
		}
	};

	const render = () => {
		if (executing) {
			return <LoadingSpinner />;
		}
		if (forbiddenError) {
			return <SimpleModal showModal={true} title={""} content={forbiddenError.Message} closeModal={handleCloseModal} />;
		}
		else {
			return children;
		}
	}

	return (<>{render()}</>);
};
