import "./Footer.scss";

const Footer = (props) => {
	return (
		<footer>
			<ul className="centred">
				<li><a id="terms-conditions" href="/site/legal/terms-and-conditions.html" target="_blank" dangerouslySetInnerHTML={{ __html: props.tc }} /></li>
				<li><a id="privacy-policy" href="/site/legal/privacy-policy.html" target="_blank" dangerouslySetInnerHTML={{ __html: props.pp }} /></li>
				<li><a id="contact-us" href="mailto:support@ucskills.com" dangerouslySetInnerHTML={{ __html: props.cu }} /></li>
			</ul>
		</footer>
	);
};

export default Footer;