import { useEffect, useState } from "react";

const useBranding = () => {
	const [gradient, setGradient] = useState(false);
	const [watermark, setWatermark] = useState({ revertToDefault: true, src: "" });

	useEffect(() => {
		const brandingItem = sessionStorage.getItem("branding");
		if ((brandingItem) && (brandingItem[0] === '{')) {
			const branding = JSON.parse(brandingItem);
			setWatermark({ revertToDefault: false, src: branding.PodHeaderBackgroundImage });
			setGradient(branding.Gradient);
		}
	}, []);
	
	return { gradient, watermark };
};

export default useBranding;