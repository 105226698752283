import { useCallback, useEffect, useState } from "react";
import "./Profile.scss";
import Pod from "components/Pod/Pod";
import { SEPARATOR } from "utils/constants";
import ContactDetails from "components/ContactDetails/ContactDetails";
import useBranding from "hooks/useBranding";
import { DataFetcher } from "components/DataFetcher";
import { Button, ButtonType } from "components/Button/Button";
import { getFetchOptions } from "../../utils/fetch";

const Profile = () => {
	const { gradient, watermark } = useBranding();
	const [userData, setUserData] = useState([]);
	const [fetchStep, setFetchStep] = useState(1);
	const [fetchData, setFetchData] = useState({});
	const [data, setData] = useState(null);
	const [showSave, setShowSave] = useState(false);

	const [initialState, setInitialState] = useState({
		serviceListsEnabled: false,
		productMarketingListsEnabled: false,
		softBusinessMarketingListsEnabled: false
	});

	const [currentState, setCurrentState] = useState({
		serviceListsEnabled: false,
		productMarketingListsEnabled: false,
		softBusinessMarketingListsEnabled: false
	});

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	useEffect(() => {
		setFetchStep(1);
	}, []);

	useEffect(() => {
		if (fetchStep === 1) {
			setFetchRequestData("/api/Profile/GetMarketingConsentPreferences");
		}
		else {
			const options = getFetchOptions({
				method: "PUT",
				body: {
					ServiceListsEnabled: currentState.serviceListsEnabled,
					ProductMarketingListsEnabled: currentState.productMarketingListsEnabled,
					SoftBusinessMarketingListsEnabled: currentState.softBusinessMarketingListsEnabled
				}
			});
			setFetchRequestData("/api/Profile/UpdateMarketingConsentPreferences", options);
		}
	}, [fetchStep, setFetchRequestData, currentState]);

	useEffect(() => {
		if (data) {
			if (typeof data === "boolean") {
				setFetchStep(1);
			}
		}
	}, [data]);

	useEffect(() => {
		const userDataEncoded = sessionStorage.getItem("userData");
		if (userDataEncoded) {
			try {
				const userData = atob(userDataEncoded);
				const userDataArray = userData.split(SEPARATOR);
				setUserData({
					Name: userDataArray[0],
					Email: userDataArray[1],
				});
			} catch (error) {
				console.error("Failed to decode or process userData:", error);
			}
		}
	}, []);

	useEffect(() => {
		if (data) {
			const initial = {
				serviceListsEnabled: data.ServiceListsEnabled,
				productMarketingListsEnabled: data.ProductMarketingListsEnabled,
				softBusinessMarketingListsEnabled: data.SoftBusinessMarketingListsEnabled
			};
			console.debug(initial);
			setInitialState(initial);
			setCurrentState(initial);
		}
	}, [data]);

	useEffect(() => {
		const hasChanges =
			currentState.serviceListsEnabled !== initialState.serviceListsEnabled ||
			currentState.productMarketingListsEnabled !== initialState.productMarketingListsEnabled ||
			currentState.softBusinessMarketingListsEnabled !== initialState.softBusinessMarketingListsEnabled;
		setShowSave(hasChanges);
	}, [currentState, initialState]);

	const handleServiceListChange = () => {
		setCurrentState((prevState) => ({
			...prevState,
			serviceListsEnabled: !prevState.serviceListsEnabled,
		}));
	};

	const handleProductMarketingChange = () => {
		setCurrentState((prevState) => ({
			...prevState,
			productMarketingListsEnabled: !prevState.productMarketingListsEnabled,
		}));
	};

	const handleSoftAndBusinessChange = () => {
		setCurrentState((prevState) => ({
			...prevState,
			softBusinessMarketingListsEnabled: !prevState.softBusinessMarketingListsEnabled,
		}));
	};

	const handlePreferencesChange = () => {
		setFetchStep(2);
	};

	return (
		<div className="profile grid-two-col gutters">
			<Pod title="Profile Management" gradient={gradient} watermark={watermark}>
				<div className="profile-details">
					<h3>Email</h3>
					<p>{userData.Email}</p>
					<h3>Name</h3>
					<p>{userData.Name}</p>
					<DataFetcher fetchData={fetchData} setData={setData} />
					{data && data.ShowMarketing === true && <>
						<h3>Marketing Consent</h3>
						<div>
							<p>Review and change your contact preferences</p>
							<p className="consent-intro"><strong>I allow iTEL to contact me with:</strong></p>
							<p>
								<label className="checkbox-row">
									<input
										type="checkbox"
										checked={currentState.serviceListsEnabled}
										onChange={handleServiceListChange}
									/>
									<p>Product and Service Updates (recommended)</p>
								</label>
							</p>
							{data.IsAdmin &&
								<>
									<p>
										<label className="checkbox-row">
											<input
												type="checkbox"
												checked={currentState.productMarketingListsEnabled}
												onChange={handleProductMarketingChange}
											/>
											<p>Product and Service Promotions and Deals</p>
										</label>
									</p>
									{data.HasSoftBusiness &&
										<p>
											<label className="checkbox-row">
												<input
													type="checkbox"
													checked={currentState.softBusinessMarketingListsEnabled}
													onChange={handleSoftAndBusinessChange}
												/>
												<p>Soft and Business Skills Promotions and Deals</p>
											</label>
										</p>
									}
								</>
							}
							<div className="save">
								{showSave && <Button type={ButtonType.Primary} text="Save" onClick={handlePreferencesChange} />}
							</div>
						</div>
					</>}
				</div>
			</Pod>
			<Pod title="Contact Us" gradient={gradient} watermark={watermark}>
				<ContactDetails />
			</Pod>
		</div>
	);
};

export default Profile;