import "./Modal.scss"; 
import useBranding from "hooks/useBranding";
import waterfall from "assets/images/waterfall.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonType } from "components/Button/Button";
import { useState } from "react";

const SimpleModal = ({ showModal, title, content, closeModal }) => {
	const { gradient, watermark } = useBranding();
	const [show, setShow] = useState(showModal);

	let watermarkSource = waterfall;
	if (watermark) {
		if (watermark.src) {
			watermarkSource = watermark.src;
		}
		else if (!watermark.revertToDefault) {
			watermarkSource = null;
		}
	}

	const handleOnClick = () => {
		if (closeModal) {
			closeModal();
		}
		setShow(false);
	}

	if (!show) {
		return null;
	}

	return (
		<div className="modal">
			<div className="modal-backdrop" onClick={closeModal}></div>
			<div className="modal-container">
				<div className="modal-header">
					<h1>{title}</h1>
					{watermarkSource && <img alt="Pod Background Detail" className="podHeader-image" src={watermarkSource} />}
					{gradient && <span className="podHeader-grad"></span>}
					<button className="modal-close" onClick={closeModal}><FontAwesomeIcon icon={faXmark} /></button>
				</div>
				<div className="modal-content">
					{content}
					<div className="modal-buttons">
						<Button type={ButtonType.Secondary} text="Ok" onClick={handleOnClick} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SimpleModal;