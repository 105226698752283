import { useEffect, useState } from "react";
import { DataTable as PrTable } from "primereact/datatable";
import SearchBox from "features/Search/SearchBox/SearchBox";
import { Column } from "primereact/column";
import "./DataTable.scss";

/**
 * Properties Object definition used in the DataTable component.
 * @typedef {Object} DataTableProps
 * @property {Array.<Object>} columns
 * @property {Array.<Object>} rows
 * @property {string} columnIdName
 * @property {Function} onRowClick
 * @property {number} [rowsPerPage=10] - Number of rows per page (optional, default is 10)
 */

/**
 * @component
 * @param {DataTableProps} props
 */
const DataTable = (props) => {
	const [filterValue, setFilterValue] = useState("");
	const [clearSearch, setClearSearch] = useState(false);
	
	useEffect(() => {
		if (props.filters && props.filters["global"] && props.filters["global"].value !== null) {
			if (!props.filters["global"].value.length) {
				setClearSearch(true);
				setFilterValue("");
			}
		}
	}, [props.filters]);

	if ((props.rows) && (props.rows.length)) {
		const { rowsPerPage = 10 } = props;
		const rowsPerPageOptions = [rowsPerPage, 25, 100].sort((a, b) => a - b);
		
		const handleKeyDown = (searchValue) => {
			setClearSearch(false);
			setFilterValue(searchValue);
			if (props.handleFilter) {
				props.handleFilter(searchValue);
			}
		};

		const renderHeader = () => {
			if (!props.filters) {
				return (<></>);
			}
			return (
				<SearchBox clear={clearSearch} placeholder="Search Timeline" handleKeyDown={handleKeyDown} />
			);
		};

		const tableProps = {
			value: props.rows,
			paginator: props.rows.length > rowsPerPage,
			filters: props.filters,
			globalFilterFields: props.globalFilterFields,
			removableSort: true,
			rows: rowsPerPage,
			rowsPerPageOptions: rowsPerPageOptions,
			header: renderHeader,
			paginatorTemplate: "CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
			currentPageReportTemplate: "{first} - {last} of {totalRecords}",
			rowClassName: props.rowClassName
		};

		if (props.onRowClick) {
			tableProps.onRowClick = props.onRowClick;
			tableProps.selectionMode = "single";
		}

		return (
			<PrTable className="data-table" {...tableProps}>
				{props.columns.map((col, i) => (
					<Column
						key={col.field || i}
						field={col.field}
						sortable={col.sortable !== undefined ? col.sortable : false}
						header={col.headerName}
						body={(rowData) => {
							// Check if valueGetter returns a string (assumed HTML) or a React component/element
							const cellContent = col.valueGetter(rowData);
							return typeof cellContent === 'string' ? (
								<span dangerouslySetInnerHTML={{ __html: cellContent }} />
							) : (
								cellContent
							);
						}}
						headerClassName={col.class}
						style={col.style}
					/>
				))}
			</PrTable>
		);
	}
	return null;
};

export default DataTable;
