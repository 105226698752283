import { useState } from 'react';
import Pod from '../../components/Pod/Pod';
import useBranding from 'hooks/useBranding';
import Device from './components/Device/Device';
import './DeviceSelection.scss';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType } from '../../components/Button/Button';
import { useModal } from 'components/Modal/context/ModalContext';

const DeviceSelection = () => {
	const { gradient, watermark } = useBranding();
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [groupName, setGroupName] = useState('My Device Group');

	// Dummy devices list
	const dummyDevices = [
		{ id: 101, name: 'Yealink' },
		{ id: 102, name: 'Cisco' },
		{ id: 103, name: 'Avaya' },
	];

	const handleToggleDevice = (device) => {
		if (selectedDevices.some((selected) => selected.id === device.id)) {
			setSelectedDevices(selectedDevices.filter((selected) => selected.id !== device.id));
		} else {
			setSelectedDevices([...selectedDevices, device]);
		}
	};

	const handleRemoveDevice = (deviceId) => {
		setSelectedDevices(selectedDevices.filter((device) => device.id !== deviceId));
	};

	const handleSelectAll = () => {
		if (selectedDevices.length === dummyDevices.length) {
			setSelectedDevices([]);
		} else {
			setSelectedDevices(dummyDevices);
		}
	};

	const { openModal } = useModal();

	const handleSave = () => {
		const deviceNames = selectedDevices.map(device => device.name).join(', ');
		alert(`Group Name: ${groupName}\nDevices saved: ${deviceNames}`);
	};

	const handleSaveModal = () => {
		openModal(
			"Are you sure you want to save these changes?",
			null,
			false,
			true,
			handleSave,
		);
	};

	// Determine the text for the select/deselect button
	const selectAllText = selectedDevices.length === dummyDevices.length ? "Deselect All Devices" : "Select All Devices";

	return (
		<div className="grid-two-col gutters">
			<Pod title="Devices" gradient={gradient} watermark={watermark}>
				<div className="device-grid">
					{dummyDevices.map((device) => (
						<Device
							key={device.id}
							id={device.id}
							name={device.name}
							onToggle={() => handleToggleDevice(device)}
							isSelected={selectedDevices.some((selected) => selected.id === device.id)}
						/>
					))}
				</div>
			</Pod>
			<Pod title="Selected Devices" gradient={gradient} watermark={watermark} sticky>

				<div className="control-panel">
					<h3 htmlFor="groupName">Group Name</h3>
					<input
						type="text"
						id="groupName"
						value={groupName}
						onChange={(e) => setGroupName(e.target.value)}
					/>
					<h3>Selected Devices</h3>
					{selectedDevices.length > 0 ? (
						selectedDevices.map((device) => (
							<div key={device.id} className="selected-device">
								<p>{device.name}</p>
								<div className="selected-device-delete" onClick={() => handleRemoveDevice(device.id)}><FontAwesomeIcon icon={faXmark} /></div>
							</div>
						))
					) : (
						<div className="no-selected-device"><p>No devices selected.</p></div>
					)}

					<div className="selected-device-button">
						<Button
							type={ButtonType.Secondary}
							text={selectAllText}
							onClick={handleSelectAll}
						/>
						<Button
							type={ButtonType.Primary}
							text="Save"
							onClick={handleSaveModal}
							disabled={selectedDevices.length === 0 || groupName.trim() === ''}
						/>
					</div>
				</div>
			</Pod>
		</div>
	);
};

export default DeviceSelection;
