import { Button, ButtonType } from "components/Button/Button";
import { DataFetcher } from "components/DataFetcher";
import { useState } from "react";
import "./PlayerDetails.scss";

const PlayerDetails = ({ previousSection, nextSection, subModule, courseLink, moduleLink, handleClickSectionItem }) => {
	const fetchData = { url: "/api/GetPreviousAndNextSubModules/" + moduleLink.Id + "/" + subModule.Id };
	const [data, setData] = useState(null);

	// Truncate text utility function
	const truncateText = (text, maxLength = 20) => {
		return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
	};

	const renderPreviousButton = () => {
		if (previousSection) {
			const text = truncateText(previousSection.title);
			return <Button type={ButtonType.Secondary} text={text} flipped="true" onClick={() => handleClickSectionItem(previousSection.id)} />;
		}
		else if (data?.Previous) {
			const text = truncateText(data.Previous.Name);
			data.Previous.Course = courseLink;
			data.Previous.Module = moduleLink;
			return <Button
				type={ButtonType.Primary}
				text={text}
				flipped="true"
				to={`/player#courseId-${courseLink.Id}-moduleId-${moduleLink.Id}-subModuleId-${data.Previous.Id}`}
			/>;
		}
		return null;
	};

	const renderNextButton = () => {
		if (nextSection) {
			const text = truncateText(nextSection.title);
			return <Button type={ButtonType.Secondary} text={text} onClick={() => handleClickSectionItem(nextSection.id)} />;
		}
		else if (data?.Next) {
			const text = truncateText(data.Next.Name);
			data.Next.Course = courseLink;
			data.Next.Module = moduleLink;
			return <Button type={ButtonType.Primary} text={text} to={`/player#courseId-${courseLink.Id}-moduleId-${moduleLink.Id}-subModuleId-${data.Next.Id}`} />;
		}
		return null;
	};

	return (
		<DataFetcher fetchData={fetchData} setData={setData}>
			<div className="player-details">
				{data && (
					<div className="player-details-navigation">
						{renderPreviousButton()}
						{renderNextButton()}
					</div>
				)}
			</div>
		</DataFetcher>
	);
};

export default PlayerDetails;
