import ucskillsLogo from "assets/ucSKILLS-colour.svg";
import Footer from "components/Footer";
import WaitSpinner from "components/WaitSpinner";
import useFetch from "hooks/useFetch";
import useInput from "hooks/useInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import LocalizedStrings from "react-localization";
import { useNavigate } from "react-router-dom";
import { MIN_PASSWORD_LENGTH, SEPARATOR } from "utils/constants";
import { getFetchOptions } from "utils/fetch";
import localizations from "./../../localization";
import "./Login.scss";

const Login = () => {
	const [waiting, setWaiting] = useState(true);

	window.sessionStorage.clear();
	window.indexedDB.deleteDatabase("ucskills-search");

	const strings = useMemo(() => new LocalizedStrings(localizations), []);
	strings.setLanguage("en_GB");

	const [efSetup, setEfSetup] = useState(false);
	const [email, onChangeEmail] = useInput("");
	const [emailValidation, setEmailValidation] = useState({ valid: 0, error: "" });
	const [passwordShown, setPasswordShown] = useState(false);
	const [password, onChangePassword] = useInput("");
	const [passwordValidation, setPasswordValidation] = useState({ valid: 0, error: "" });
	const [serverErrorMessage, setServerErrorMessage] = useState("");
	const navigate = useNavigate();
	const { execute } = useFetch();

	/**
	 * Generate a "dummy" call to the Server for an initial Entity Framework request, for "warming-up".
	 */
	useEffect(() => {
		function onDatabaseCheckComplete() {
			setEfSetup(true);
			console.log("Entity Framework initialised.");
			setWaiting(false);
			window.indexedDB.deleteDatabase("dummy");
		}

		fetch("/api/Setup/")
			.then(function (response) {
				sessionStorage.showSearch = true;
				let request = window.indexedDB.open("dummy", 1);
				request.addEventListener("success", () => {
					onDatabaseCheckComplete();
				});
				request.addEventListener("error", () => {
					if (navigator.userAgent.indexOf("Firefox/") !== -1) {
						sessionStorage.showSearch = false;
						onDatabaseCheckComplete();
					}
				});

				response.text().then(function (result) {
					if (result === "false") {
						localStorage.removeItem("heapId");
					} else if (!localStorage.heapId || localStorage.heapId !== result) {
						localStorage.heapId = result;
					}
				});
			});
	}, []);

	const navigateToCorrectLocation = useCallback(() => {
		const userData = window.atob(sessionStorage.userData);
		const userDataArray = userData.split(SEPARATOR);
		let key = "ucs-lau-" + window.btoa(userDataArray[1]);
		let lau = localStorage.getItem(key);
		if (!lau) {
			key = "ucs-lau-anon";
			lau = localStorage.getItem(key);
		}
		if (lau) {
			navigate(lau, { replace: true });
			localStorage.removeItem(key);
		}
		else {
			navigate("/dashboard", { replace: true });
		}
	}, []);

	useEffect(() => {
		function onLoginRequestFailed(error) {
			if (("Status" in error) && (error.Status === 500)) {
				// Unhandled exception.
				let w = window.open("", "_blank");
				w.document.write(error.message);
			}
			else {
				let errorMessage = "";
				if (error.Message in strings) {
					errorMessage = strings[error.Message];
				}
				else if ("ExceptionMessage" in error) {
					errorMessage = [strings.ERROR, "<br />", error.ExceptionType, ": ", error.ExceptionMessage].join("");
				}
				else {
					errorMessage = error.Message;
				}
				setServerErrorMessage(errorMessage);
			}
		}

		if ((efSetup) && (emailValidation.valid === 2) && (passwordValidation.valid === 2) && (!serverErrorMessage.length)) {
			setWaiting(true);
			const options = getFetchOptions({
				method: "POST",
				auth: btoa(email + ":" + password)
			});
			execute("api/Login/", options, (content, error) => {
				setWaiting(false);
				if (error) {
					onLoginRequestFailed(error);
				}
				else {
					if (content.v3) {
						// Ensure the information required for ucSKILLS usage has been delivered.
						if ((!content.Authentication) || (!content.UserData)) {
							return;
						}
						sessionStorage.auth = content.Authentication;
						sessionStorage.hasReports = content.HasReports;
						sessionStorage.userData = content.UserData;
						sessionStorage.permissions = content.Permissions;
						sessionStorage.branding = content.Branding;
						sessionStorage.ff = content.FF;
						sessionStorage.nps = content.Nps;
						sessionStorage.gurus = JSON.stringify(content.Gurus);
						navigateToCorrectLocation();
					}
					else {
						localStorage.data = JSON.stringify(content);
						window.location.replace("site/main.html");
					}
				}
			});
		}
	}, [efSetup, email, emailValidation, password, passwordValidation, serverErrorMessage, strings]);

	function handleClickTogglePassword() {
		setPasswordShown(!passwordShown);
	}

	function handleClickSubmit() {
		setServerErrorMessage("");
		if (!email.trim().length) {
			setEmailValidation({ valid: 1, error: "ERR_USER_EMPTY" });
		}
		else {
			setEmailValidation({ valid: 2 });
		}
		if (!password.trim().length) {
			setPasswordValidation({ valid: 1, error: "ERR_PASSWORD_EMPTY" });
		}
		else if (password.trim().length < MIN_PASSWORD_LENGTH) {
			setPasswordValidation({ valid: 1, error: "ERR_PASSWORD_TOOSHORT" });
		}
		else {
			setPasswordValidation({ valid: 2 });
		}
	}

	function handleKeyUpEmail() {
		setEmailValidation({ valid: 0 });
	}

	function handleKeyUpPassword(event) {
		setPasswordValidation({ valid: 0 });
		if (event.keyCode === 13) {
			handleClickSubmit();
		}
	}

	return (
		<>
			<div id="container">
				{waiting && <WaitSpinner />}
				<div id="logo" className="centred">
					<img src={ucskillsLogo} alt="ucSKILLS Logo" width="150" height="89" />
				</div>
				<div id="content" className="centred">
					<div>
						<h1 id="title">{strings.LOGIN_TITLE}</h1>
					</div>
					<div id="email">
						<input
							id="email-value"
							className={(emailValidation.valid === 1) ? "is-invalid" : ""}
							maxLength="320"
							autoFocus="{true}"
							placeholder={strings.EMAIL_ADDRESS}
							value={email}
							onChange={onChangeEmail}
							onKeyUp={handleKeyUpEmail}
						/>
						<div className={`invalid-feedback ${emailValidation.valid === 0 ? "hidden" : ""}`}>{strings[emailValidation.error]}</div>
					</div>
					<div id="sesame">
						<input
							id="sesame-value"
							className={(passwordValidation.valid === 1) ? "is-invalid" : ""}
							type={passwordShown ? "text" : "password"}
							maxLength="64"
							placeholder={strings.PASSWORD}
							value={password}
							onChange={onChangePassword}
							onKeyUp={handleKeyUpPassword}
						/>
						<button
							id="sesame-reveal"
							className={passwordShown ? "unrevealed" : "revealed"}
							onClick={handleClickTogglePassword}
							title={passwordShown ? strings.CLICK_SHOW_PASSWORD : strings.CLICK_HIDE_PASSWORD}
						/>
						<div className={`invalid-feedback ${passwordValidation.valid === 0 ? "hidden" : ""}`}>{strings[passwordValidation.error]}</div>
					</div>
					<a id="sesame-forgot" href="/site/verify.html?T=1">{strings.FORGOT_PASSWORD}</a>
					<div id="errors" className="error-message">
						<span dangerouslySetInnerHTML={{ __html: serverErrorMessage }} />
					</div>
					<button id="submit" onClick={handleClickSubmit}>{strings.CLICK_LOGIN}</button>
				</div>
			</div>
			<Footer tc={strings.TERMS_AND_CONDITIONS} pp={strings.PRIVACY_POLICY} cu={strings.CONTACT_US} />
		</>
	);
};

export default Login;